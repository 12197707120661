import { graphql, useStaticQuery } from 'gatsby';

const detailsQuery = graphql`
  query MetadataQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const useMetadata = () => useStaticQuery(detailsQuery);

export default useMetadata;
