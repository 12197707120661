import styled from 'styled-components';
import { rhythm } from '../utils/typography';

const LayoutWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(24)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
  /* @media (min-width: 736px) {
    padding-left: ${rhythm(3)};
    padding-right: ${rhythm(3)};
  } */
`;

export default LayoutWrapper;
