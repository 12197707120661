import styled from 'styled-components';
import Link from './Link';

const NavItem = styled(Link)`
  margin-right: 15px;
  font-weight: bold;
  box-shadow: none;
`;

export default NavItem;
