import React from 'react';
import Nav from '../components/Nav';
import NavItem from '../components/NavItem';

const links = [
  {
    title: 'En esto creo',
    path: '/en-esto-creo',
  },
  // {
  //   title: 'Lo que he leído',
  //   path: '/lo-que-he-leido',
  // },
  {
    title: 'Escrito por',
    path: 'https://escritopor.davidtaboas.com/',
  },
  // {
  //   title: 'En qué puedo ayudarte',
  //   path: '/freelance',
  // },
  {
    title: 'LinkedIn',
    path: 'http://linkedin.com/in/davidtaboas',
  },
];

const Menu = () => (
  <Nav>
    {links.map((link, index) => (
      <NavItem key={index} to={link.path}>
        {link.title}
      </NavItem>
    ))}
  </Nav>
);

export default Menu;
