import styled from 'styled-components';

import { rhythm } from '../utils/typography';

const Nav = styled.nav`
  display: flex;
  margin-bottom: ${rhythm(1.5)};
  flex-wrap: wrap;
`;

export default Nav;
