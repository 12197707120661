import React from 'react';
import { Link } from 'gatsby';

import { rhythm, scale } from '../utils/typography';
import LayoutWrapper from './LayoutWrapper';
import Menu from '../containers/Menu';

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(0.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      );
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
            marginBottom: rhythm(0.5),
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <LayoutWrapper>
        {header}
        <Menu />
        {children}
        {/* <footer>
          <a href="http://linkedin.com/in/davidtaboas" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
          {` • `}
          <a href="https://github.com/davidtaboas" target="_blank" rel="noopener noreferrer">
            github
          </a>
          {` • `}
          <a href="https://stackoverflow.com/users/3979284/david-t%C3%A1boas" target="_blank" rel="noopener noreferrer">
            stack overflow
          </a>
        </footer> */}
      </LayoutWrapper>
    );
  }
}

export default Layout;
